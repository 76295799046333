<template>
  <div class="d-flex justify-content-left">
    <div class="text-success" v-if="isTrue()">
      <font-awesome-icon icon="check-circle" />
    </div>
    <div class="text-danger" v-else>
      <font-awesome-icon icon="times-circle" />
    </div>
  </div>
</template>

<script>
export default {
  name: "TableBoolean",

  props: {
    field: {
      type: String,
      required: true,
    },
  },

  methods: {
    isTrue() {
      return this.data[this.field] === true;
    },
  },
};
</script>
