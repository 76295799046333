<template>
  <div>
    <CRow class="justify-content-between mb-3">
      <CCol sm="auto"><div class="ui-type-display-lg">Impostazioni</div> </CCol>
    </CRow>

    <CCard>
      <CCardBody>
        <CAlert v-if="error.hasGeneralErrors()" color="danger" class="mb-3">
          {{ error.general().join(" ") }}
        </CAlert>

        <form action="" @submit.prevent="onSubmit">
          <CRow>
            <CCol md="6">
              <CInput
                v-model="form.name"
                label="Nome"
                type="text"
                :isValid="error.isValidField('name')"
                :invalidFeedback="error.fieldError('name')"
                required
              />
            </CCol>

            <CCol md="6">
              <CSelect
                label="Tipo"
                :options="familyTypeOptions"
                :value.sync="form.type"
                :isValid="error.isValidField('f_type')"
                :invalidFeedback="error.fieldError('f_type')"
                custom
              />
            </CCol>
          </CRow>
          <vue-ladda
            :loading="loading"
            data-style="zoom-in"
            button-class="btn btn-primary px-4"
            type="submit"
            >SALVA</vue-ladda
          >
        </form>
      </CCardBody>
    </CCard>

    <Addresses />
    <Members />
  </div>
</template>

<script>
import get from "lodash/get";
import { Page, Sort } from "@syncfusion/ej2-vue-grids";
import { GetDataManagerNew } from "../../../ds";
import errorResponse from "../../../helpers/error";
import { getFamilyTypeOptions } from "../../../helpers/options";
import Addresses from "./Addresses";
import Members from "./Members";

export default {
  name: "Settings",

  components: {
    Addresses,
    Members,
  },

  data() {
    const dm = GetDataManagerNew("auth_family");
    const familyTypeOptions = getFamilyTypeOptions();

    const { name, f_type } = get(
      this.$store,
      "state.connections.current.family"
    );

    return {
      dm: dm,
      error: errorResponse(),
      loading: false,
      form: {
        name: name,
        type: f_type,
      },
      familyTypeOptions: familyTypeOptions,
    };
  },
  provide: {
    grid: [Page, Sort],
  },

  mounted() {},

  beforeDestroy() {},

  methods: {
    onSubmit() {
      const id = get(this.$store, "state.connections.current.family.id");
      const requestParams = {
        id: id,
        name: this.form.name,
        f_type: this.form.type,
      };
      this.error.reset();
      this.dm
        .update("", requestParams)
        .then((response) => {
          this.$store.dispatch("toaster/add", {
            title: "OK!",
            text: "Conto salvato con successo",
            color: "success",
            autohide: true,
          });
          this.$store.dispatch("connections/currentRefresh");
        })
        .catch((response) => {
          const body = JSON.parse(response[0].error.response);
          this.modal.error.set(body.errors);
        });
    },
  },
};
</script>
