<template>
  <CModal :show="show" :closeOnBackdrop="false" :centered="true">
    <template #header>
      <h5 class="modal-title">{{ title }}</h5>
    </template>

    <CAlert v-if="error.hasGeneralErrors()" color="danger" class="mb-3">
      {{ error.general().join(" ") }}
    </CAlert>

    <form action="" @submit.prevent="onSubmit">
      <CInput
        v-model="form.to"
        :label="$t('models.family_address.to')"
        type="text"
        :isValid="error.isValidField('to')"
        :invalidFeedback="error.fieldError('to')"
        required
      />

      <CInput
        v-model="form.address"
        :label="$t('models.family_address.address')"
        type="text"
        :isValid="error.isValidField('address')"
        :invalidFeedback="error.fieldError('address')"
        required
      />

      <CInput
        v-model="form.city"
        :label="$t('models.family_address.city')"
        type="text"
        :isValid="error.isValidField('city')"
        :invalidFeedback="error.fieldError('city')"
        required
      />

      <CSelect
        :label="$t('models.family_address.country')"
        :options="countryOptions"
        :value.sync="form.country"
        :isValid="error.isValidField('country')"
        :invalidFeedback="error.fieldError('country')"
        custom
      />

      <div class="form-group">
        <label>{{ $t("models.family_address.postal_code") }}</label>
        <v-select
          class="bg-white"
          label="code"
          v-model="form.postal_code"
          :options="postalCodeOptions"
          @search="fetchPostalCodeOptions"
        />
        <div
          v-if="error.hasFieldsError('postal_code')"
          class="invalid-feedback d-block"
        >
          {{ error.fieldError("postal_code") }}
        </div>
      </div>

      <CInputCheckbox
        :label="$t('models.family_address.default_ship')"
        :checked.sync="form.default_ship"
        :isValid="error.isValidField('default_ship')"
        :invalidFeedback="error.fieldError('default_ship')"
        custom
      />

      <CInputCheckbox
        :label="$t('models.family_address.default_bill')"
        :checked.sync="form.default_bill"
        :isValid="error.isValidField('default_bill')"
        :invalidFeedback="error.fieldError('default_bill')"
        custom
      />
    </form>

    <template #footer>
      <CButton color="primary" variant="outline" @click.prevent="closeModal"
        >ANNULLA</CButton
      >
      <vue-ladda
        :loading="loading"
        data-style="zoom-in"
        button-class="btn btn-primary px-4"
        @click.prevent="onSubmit"
        >CONFERMA</vue-ladda
      >
    </template>
  </CModal>
</template>

<script>
import get from "lodash/get";
import debounce from "lodash/debounce";
import pick from "lodash/pick";
import errorResponse from "../../../helpers/error";
import {
  getNationsActiveOptions,
  getPostalCodeOptions,
} from "../../../helpers/options";
import vSelect from "vue-select";
import { GetDataManagerNew } from "../../../ds";
import EventBus from "../../../helpers/EventBus";

const formDefaults = () => {
  return {
    id: null,
    to: "",
    address: "",
    city: "",
    postal_code: null,
    country: "",
    default_ship: false,
    default_bill: false,
  };
};

export default {
  name: "AddressesModal",
  components: {
    vSelect,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
      default: null,
    },
  },

  data() {
    const familyId = this.$store.state.connections.current.family.id;
    const dm = GetDataManagerNew("auth_faddress", [familyId]);

    return {
      dm: dm,
      initialized: false,
      loading: false,
      error: errorResponse(),
      form: formDefaults(),
      countryOptions: [],
      postalCodeOptions: [],
    };
  },

  watch: {
    show(value) {
      if (value === true) {
        this.init();
      }
    },
    "form.country": function () {
      if (this.initialized) {
        this.form.postal_code = null;
      }
    },
  },

  methods: {
    init() {
      this.initialized = false;
      this.form = this.isEdit ? { ...this.values } : formDefaults();
      this.getCountryOptions();
      this.$nextTick(() => {
        this.initialized = true;
      });
    },

    getCountryOptions() {
      getNationsActiveOptions().then((options) => {
        this.countryOptions = [
          { value: null, label: "- seleziona -" },
          ...options,
        ];
      });
    },

    getPostalCodeOptions: debounce((search, loading, countryId, vm) => {
      getPostalCodeOptions({ countryId, search }).then((options) => {
        loading(false);
        vm.postalCodeOptions = [...options];
      });
    }, 350),

    fetchPostalCodeOptions(search, loading) {
      const countryId = this.form.country;
      if (countryId && search.length) {
        loading(true);
        this.getPostalCodeOptions(search, loading, countryId, this);
      }
    },

    onSubmit() {
      if (this.isEdit) {
        this.updateAddress();
      } else {
        this.addAddress();
      }
    },

    prepareRequestParams(formData) {
      const fields = [
        "id",
        "to",
        "address",
        "city",
        "country",
        "default_ship",
        "default_bill",
      ];

      return {
        ...pick(formData, fields),
        postal_code: get(formData.postal_code, "id", ""),
      };
    },

    addAddress() {
      const requestParams = {
        ...this.prepareRequestParams(this.form),
      };
      this.error.reset();
      this.dm
        .insert(requestParams)
        .then(() => {
          this.$store.dispatch("toaster/add", {
            title: "OK!",
            text: "Indirizzo aggiunto con successo",
            color: "success",
            autohide: true,
          });
          this.closeModal();
          EventBus.$emit("address:refresh");
        })
        .catch((response) => {
          const body = JSON.parse(response[0].error.response);
          this.error.set(body.errors);
        });
    },

    updateAddress() {
      const requestParams = {
        ...this.prepareRequestParams(this.form),
      };
      this.error.reset();
      this.dm
        .update("", requestParams)
        .then(() => {
          this.$store.dispatch("toaster/add", {
            title: "OK!",
            text: "Indirizzo aggiornato con successo",
            color: "success",
            autohide: true,
          });
          this.closeModal();
          EventBus.$emit("address:refresh");
        })
        .catch((response) => {
          const body = JSON.parse(response[0].error.response);
          this.error.set(body.errors);
        });
    },

    closeModal() {
      this.$emit("update:show", false);
    },
  },
};
</script>
