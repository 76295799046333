<template>
  <div class="d-flex justify-content-end">
    <dropdown-menu :items="actions" :select="onActionSelect">
      <font-awesome-icon icon="ellipsis-h"
    /></dropdown-menu>
  </div>
</template>

<script>
import EventBus from "../../../helpers/EventBus";
const ADDRESS_ACTIONS = [
  { id: "EDIT", text: "Modfica" },
  { id: "DELETE", text: "Rimuovi" },
];

export default {
  name: "AddressesTableActions",

  computed: {
    actions() {
      return ADDRESS_ACTIONS;
    },
  },
  methods: {
    onActionSelect: function (args) {
      const { id } = args.item;

      if (id === "EDIT") {
        EventBus.$emit("address:edit", this.data);
      }

      if (id === "DELETE") {
        if (
          confirm(
            `Confermi di voler rimoovere l'indirizzoo "${this.data.to}"?`
          ) == true
        ) {
          EventBus.$emit("address:remove", this.data);
        }
      }
    },
  },
};
</script>
