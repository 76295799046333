<template>
  <div>
    {{ getPostalCode() }}
  </div>
</template>

<script>
import get from "lodash/get";

export default {
  name: "AddressesTablePostalCode",

  methods: {
    getPostalCode() {
      return get(this.data, "postal_code.postal_code");
    },
  },
};
</script>
