<template>
  <div>
    <CRow class="justify-content-between mb-3">
      <CCol sm="auto"><div class="ui-type-display-lg">Membri</div> </CCol>
      <CCol sm="auto">
        <CButton color="primary" @click="onInviteMember()">
          INVITA MEMBRO
        </CButton>
      </CCol>
    </CRow>

    <CCard>
      <CCardBody>
        <CCardSubtitle class="my-2">
          Gestisci i diritti di accesso e condivisione delle persone che
          collaborano in questo ruolo
        </CCardSubtitle>

        <ejs-grid
          ref="grid"
          :dataSource="dm"
          :searchSettings="searchOptions"
          :editSettings="editSettings"
          :toolbar="toolbar"
          :allowPaging="true"
          :allowSorting="true"
          :showColumnChooser="true"
        >
          <e-columns>
            <e-column
              field="id"
              headerText="ID"
              type="number"
              :isPrimaryKey="true"
              is-identity="true"
              :allowEditing="false"
              clipMode="EllipsisWithTooltip"
            ></e-column>
            <e-column
              field="user.first_name"
              :headerText="$t('models.user.name')"
              clipMode="EllipsisWithTooltip"
              :isPrimaryKey="true"
              is-identity="true"
              :allowEditing="false"
            ></e-column>
            <e-column
              field="user.last_name"
              :headerText="$t('models.user.surnname')"
              clipMode="EllipsisWithTooltip"
              :isPrimaryKey="true"
              is-identity="true"
              :allowEditing="false"
            ></e-column>
            <e-column
              field="is_active"
              :headerText="$t('models.fyc.is_active')"
              displayAsCheckBox="true"
              type="boolean"
              editType="booleanedit"
            ></e-column>
            <e-column
              field="user.invite_pending"
              :headerText="$t('models.fyc.invite_pending')"
              displayAsCheckBox="true"
              type="boolean"
              :allowEditing="false"
            ></e-column>
            <e-column
              field="permissions.role_dc"
              :headerText="$t('models.fyc.permissions.role_dc')"
              clipMode="EllipsisWithTooltip"
              foreignKeyValue="label"
              :dataSource="permissionsData"
              foreignKeyField="value"
              defaultValue="1"
            ></e-column>
            <e-column
              field="permissions.role_pp"
              :headerText="$t('models.fyc.permissions.role_pp')"
              clipMode="EllipsisWithTooltip"
              foreignKeyValue="label"
              :dataSource="permissionsData"
              foreignKeyField="value"
              defaultValue="1"
            ></e-column>
            <e-column
              field="permissions.role_lg"
              :headerText="$t('models.fyc.permissions.role_lg')"
              clipMode="EllipsisWithTooltip"
              foreignKeyValue="label"
              :dataSource="permissionsData"
              foreignKeyField="value"
              defaultValue="1"
            ></e-column>
            <e-column
              field="permissions.role_os"
              :headerText="$t('models.fyc.permissions.role_os')"
              clipMode="EllipsisWithTooltip"
              foreignKeyValue="label"
              :dataSource="permissionsData"
              foreignKeyField="value"
              defaultValue="1"
            ></e-column>
            <e-column
              field="permissions.role_fin"
              :headerText="$t('models.fyc.permissions.role_fin')"
              clipMode="EllipsisWithTooltip"
              foreignKeyValue="label"
              :dataSource="permissionsData"
              foreignKeyField="value"
              defaultValue="1"
            ></e-column>
            <e-column
              field="permissions.role_exp"
              :headerText="$t('models.fyc.permissions.role_exp')"
              clipMode="EllipsisWithTooltip"
              foreignKeyValue="label"
              :dataSource="permissionsData"
              foreignKeyField="value"
              defaultValue="1"
            ></e-column>
          </e-columns>
        </ejs-grid>

        <CModal :show="modal.show" :closeOnBackdrop="false" :centered="true">
          <template #header>
            <h5 class="modal-title">INVITA MEMBRO</h5>
          </template>

          <CAlert
            v-if="modal.error.hasGeneralErrors()"
            color="danger"
            class="mb-3"
          >
            {{ modal.error.general().join(" ") }}
          </CAlert>

          <form action="" @submit.prevent="inviteMember">
            <CInput
              v-model="modal.form.name"
              label="Nome"
              type="text"
              :isValid="modal.error.isValidField('name')"
              :invalidFeedback="modal.error.fieldError('name')"
              required
            />
            <CInput
              v-model="modal.form.surname"
              label="Cognome"
              type="text"
              :isValid="modal.error.isValidField('surname')"
              :invalidFeedback="modal.error.fieldError('surname')"
              required
            />
            <CInput
              v-model="modal.form.email"
              label="Email"
              type="email"
              :isValid="modal.error.isValidField('email')"
              :invalidFeedback="modal.error.fieldError('email')"
              required
            />
          </form>

          <template #footer>
            <CButton
              color="primary"
              variant="outline"
              @click.prevent="closeModal"
              >ANNULLA</CButton
            >
            <vue-ladda
              :loading="modal.loading"
              data-style="zoom-in"
              button-class="btn btn-primary px-4"
              @click.prevent="inviteMember"
              >INVITA</vue-ladda
            >
          </template>
        </CModal>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { GetDataManagerNew } from "../../../ds/index";
import { DataManager } from "@syncfusion/ej2-data";
import errorResponse from "../../../helpers/error";
import {
  Toolbar,
  Edit,
  Page,
  Sort,
  Filter,
  Search,
  Reorder,
  Resize,
  ForeignKey,
} from "@syncfusion/ej2-vue-grids";
export default {
  name: "Members",

  provide: {
    grid: [
      Edit,
      Toolbar,
      Page,
      Sort,
      Filter,
      Search,
      Reorder,
      Resize,
      ForeignKey,
    ],
  },

  data() {
    const familyId = this.$store.state.connections.current.family.id;
    const dm = GetDataManagerNew("auth_family_connection", [familyId]);
    return {
      dm: dm,
      searchOptions: {
        fields: ["user.first_name", "user.last_name", "id"],
      },
      editSettings: {
        allowEditing: true,
        allowAdding: false,
        allowDeleting: false,
        mode: "Normal",
      },
      toolbar: ["Add", "Edit", "Update", "Cancel"],
      toolbarOptions: [
        "Add",
        "Edit",
        "Update",
        "Cancel",
        "Search",
        "ColumnChooser",
      ],

      permissionsData: new DataManager([
        { value: 0, label: this.$t("models.fyc.permissions.options.0") },
        { value: 1, label: this.$t("models.fyc.permissions.options.1") },
        { value: 2, label: this.$t("models.fyc.permissions.options.2") },
      ]),

      modal: {
        show: false,
        loading: false,
        error: errorResponse(),
        form: {
          name: "",
          surname: "",
          email: "",
        },
      },
    };
  },

  methods: {
    onInviteMember() {
      this.modal.error.reset();
      this.modal.form = {
        name: "",
        surname: "",
        email: "",
      };
      this.modal.show = true;
    },

    closeModal() {
      this.modal.show = false;
    },

    inviteMember() {
      const requestParams = {
        name: this.modal.form.name,
        surname: this.modal.form.surname,
        email: this.modal.form.email,
      };
      this.modal.error.reset();
      this.dm
        .insert(requestParams)
        .then((response) => {
          this.$store.dispatch("toaster/add", {
            title: "OK!",
            text: "Invito inviato con successo",
            color: "success",
            autohide: true,
          });
          this.modal.show = false;
          this.$refs.grid.refresh();
        })
        .catch((response) => {
          const body = JSON.parse(response[0].error.response);
          this.modal.error.set(body.errors);
        });
    },
  },
};
</script>
